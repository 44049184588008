<template>
  <div class="field col-span-12 md:col-span-12">
    <label for="address-lines" class="c-required">{{ t("common.address.address-lines.label") }}</label>
    <PrimeTextarea
      id="address-lines"
      v-model="addressLinesComputed"
      type="text"
      class="inputfield w-full"
      :placeholder="t('common.address.address-lines.placeholder')"
      :class="{ 'p-invalid': val.addressLines.$error }"
      @change="onChangeComplete"
      :data-testid="`${addressType}-address-lines`"
    />
    <small class="p-error" v-if="val.addressLines.$error" :id="`${addressType}-address-lines-error`">
      {{ val.addressLines.$errors[0].$message }}
    </small>
  </div>
  <div class="field col-span-12 md:col-span-12">
    <label for="address-country" class="c-required">{{ t("common.address.country.label") }}</label>
    <Select
      id="address-country"
      :options="countryOptions"
      optionLabel="name"
      optionValue="iso"
      v-model="selectedCountryComputed"
      class="inputfield w-full"
      :class="{ 'p-invalid': val.countryIso.$error }"
      :placeholder="t('common.address.country.placeholder')"
      @change="onChangeComplete"
      :data-testid="`${addressType}-country`"
      @show="isActive = true"
      @hide="isActive = false"
      @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
    />
    <small class="p-error" v-if="val.countryIso.$error" :id="`${addressType}-country-error`">{{
      val.countryIso.$errors[0].$message
    }}</small>
  </div>

  <div class="field col-span-12 md:col-span-12">
    <label class="c-required">{{ t("common.address.postal-code-city.label") }}</label>
    <div class="formgrid grid grid-cols-12 gap-4">
      <div class="field col-span-12 md:col-span-4">
        <InputText
          id="address-postal-code"
          v-model="addressComputed.postalCode"
          maxlength="4"
          type="text"
          class="inputfield w-full"
          :class="{ 'p-invalid': val.postalCode.$error }"
          :placeholder="t('common.address.postal-code-city.placeholder-code')"
          @change="onPostalCodeChange(($event.target as HTMLInputElement).value)"
          :data-testid="`${addressType}-postal-code`"
        />
        <small class="p-error" v-if="val.postalCode.$error" :id="`${addressType}-postal-code-error`">{{
          val.postalCode.$errors[0].$message
        }}</small>
      </div>
      <div class="field col-span-12 md:col-span-8">
        <InputText
          id="address-city"
          type="text"
          v-model="addressComputed.city"
          class="inputfield w-full"
          :class="{ 'p-invalid': val.city.$error }"
          :placeholder="t('common.address.postal-code-city.placeholder-city')"
          @change="onChangeComplete"
          :data-testid="`${addressType}-city`"
        />
        <small class="p-error" v-if="val.city.$error" :id="`${addressType}-city-error`">{{
          val.city.$errors[0].$message
        }}</small>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Address } from "@/repositories/company/model/Address";
import { Country } from "@/repositories/country/model/Country";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { computed, onMounted, ref } from "vue";
import cloneDeep from "lodash.clonedeep";
import { useI18n } from "vue-i18n";
import { useCountry } from "@/repositories/country/CountryService";

const { getAllCountries, getCityByPostCode } = useCountry();
const { t } = useI18n();

const props = defineProps<{
  address: Address;
  addressType: string;
}>();

const emit = defineEmits<{
  (e: "setAddress", value: Address): void;
}>();

const isActive = ref(false);

const countryOptions = ref<Country[]>([]);
const fetchCountryData = async () => {
  countryOptions.value = await getAllCountries();
};

const addressComputed = computed<Address>(() => {
  return props.address ? cloneDeep(props.address) : new Address();
});

const addressLinesComputed = computed<string>({
  get: () => {
    return addressComputed.value.addressLines ? addressComputed.value.addressLines.join("\n") : "";
  },
  set: (val: string) => {
    addressComputed.value.addressLines = val.toString().replace(/\r\n/g, "\n").split("\n");
  },
});

const selectedCountryComputed = computed<string>({
  get: () => {
    return addressComputed.value?.countryIso ?? "";
  },
  set: (value) => {
    if (value !== undefined) {
      addressComputed.value.countryIso = value;
    }
  },
});

const onChangeComplete = () => {
  emit("setAddress", addressComputed.value);
};

const onPostalCodeChange = async (code: string) => {
  if (code.trim() === "") {
    addressComputed.value.city = "";
  } else {
    const result = await getCityByPostCode(code);
    addressComputed.value.city = result[0].city;
  }

  onChangeComplete();
};

onMounted(async () => {
  await fetchCountryData();
});

const rules = {
  addressLines: {
    required: helpers.withMessage(t("common.address.address-lines.error"), required),
    $each: helpers.withMessage(t("common.address.address-lines.error"), (value: string[]) => {
      return value !== undefined && value[0].length > 1;
    }),
  },
  postalCode: {
    required: helpers.withMessage(t("common.address.postal-code.error"), required),
  },
  city: {
    required: helpers.withMessage(t("common.address.city.error"), required),
  },
  countryIso: {
    required: helpers.withMessage(t("common.address.country.error"), required),
  },
};

const val = useVuelidate(rules, addressComputed);
</script>
