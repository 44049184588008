<template>
  <div class="field col-span-12 md:col-span-6">
    <label for="company-banking" class="c-required"> {{ t(`company.banking.label`) }} </label>

    <InputText
      id="company-banking"
      type="text"
      v-model="name"
      data-testid="company-banking"
      class="inputfield w-full"
      :class="{ 'p-invalid': val.bankingName.$error }"
      :placeholder="t(`company.banking.placeholder`)"
      maxlength="8000"
      :unmask="true"
    />

    <small class="p-error" v-if="val.bankingName.$error" data-testid="company-banking-error">
      {{ val.bankingName.$errors[0].$message }}
    </small>
  </div>

  <div class="field col-span-12 md:col-span-6">
    <label for="company-banking" class="c-required"> {{ t(`company.account-number.label`) }} </label>

    <InputMask
      mask="9999 99 99999"
      id="company-banking-number"
      v-model="account"
      data-testid="company-banking-number"
      class="inputfield w-full"
      :class="{ 'p-invalid': val.accountNumber.$error }"
      :placeholder="t(`company.account-number.placeholder`)"
      :unmask="true"
    />

    <small class="p-error" v-if="val.accountNumber.$error" data-testid="company-account-number-error">
      {{ val.accountNumber.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

const { t } = useI18n();

const props = defineProps<{
  bankingName: string;
  accountNumber: string;
}>();

const emit = defineEmits<{
  (e: "update:bankingName", value: string): void;
  (e: "update:accountNumber", value: string): void;
}>();

const rules = {
  bankingName: {
    required: helpers.withMessage(t("company.banking.error"), required),
  },
  accountNumber: {
    required: helpers.withMessage(
      t("company.account-number.error"),
      (value: string) => value.replace(/[\s_]+/g, "").length >= 11
    ),
  },
};

const val = useVuelidate(rules, props);

const name = computed<string>({
  get: () => {
    return props.bankingName ?? "";
  },
  set: (value) => {
    emit("update:bankingName", value);
  },
});

const account = computed<string>({
  get: () => {
    return props.accountNumber ?? "";
  },
  set: (value) => {
    const bankNo = value.replace(/[\s_]+/g, "");
    if (bankNo.length >= 11 || bankNo.length === 0) {
      emit("update:accountNumber", bankNo);
    }
  },
});
</script>
