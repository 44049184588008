<template>
  <label for="company-organization-number" class="c-required"> {{ t(`company.organization-number.label`) }} </label>

  <InputMask
    mask="999 999 999"
    id="company-organization-number"
    v-model="orgNumber"
    data-testid="company-organization-number"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.organizationNumber.$error }"
    :placeholder="t(`company.organization-number.placeholder`)"
    :unmask="true"
  />

  <small class="p-error" v-if="val.organizationNumber.$error" data-testid="company-organization-number-error">
    {{ val.organizationNumber.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";

const { t } = useI18n();

const props = defineProps<{
  organizationNumber: string;
}>();

const emit = defineEmits<{
  (e: "update:organizationNumber", value: string): void;
}>();

const rules = {
  organizationNumber: {
    minLength: helpers.withMessage(
      t("company.organization-number.error"),
      (value: string) => value.replace(/[\s_]+/g, "").length >= 9
    ),
  },
};

const orgNumber = computed<string>({
  get: () => {
    return props.organizationNumber ?? "";
  },
  set: (value) => {
    const org = value.replace(/[\s_]+/g, "");
    if (org.length >= 9 || org.length === 0) {
      emit("update:organizationNumber", org);
    }
  },
});

const val = useVuelidate(rules, props);
</script>
