<template>
  <div id="company-navbar-shortcuts" v-if="useTeleport">
    <Teleport to="#c-navbar-title">
      <h1>{{ editMode ? t("company.header.edit-company") : t("company.header.add-company") }}</h1>
    </Teleport>
  </div>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <PrimeButton
          class="c-circular-button mr-4"
          @click="$emit('onSave')"
          data-testid="btn-save"
          :disabled="!hasUpdates || isSaving"
        >
          <i class="pi pi-check c-success-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.save") }}</span>
        </PrimeButton>
        <PrimeButton class="c-circular-button" data-testid="btn-cancel" @click="$emit('onCancel')" :disabled="isSaving">
          <i class="pi pi-times c-warning-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.cancel") }}</span>
        </PrimeButton>
        <PrimeButton
          v-if="editMode"
          class="c-circular-button ml-4"
          @click="onConfirmDelete"
          data-testid="btn-delete"
          :disabled="isSaving"
        >
          <i class="pi pi-trash c-delete-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.delete") }}</span>
        </PrimeButton>
        <ConfirmPopup data-testid="company-delete-confirm"></ConfirmPopup>
      </div>
    </div>
  </div>
  <UnsavedChangesDialog
    position="top"
    :visible="unsavedChangesDialogVisible"
    :dialogHeader="t('common.unsaved-changes-header')"
    @cancelClicked="emit('dialogCancelBtnClicked')"
    @discardClicked="emit('dialogDiscardBtnClicked')"
    @saveClicked="emit('onSave')"
    ><template #content>{{ t("common.unsaved-changes-text") }}</template>
    <template #cancelBtn>{{ t("common.cancel") }}</template>
    <template #discardBtn>{{ t("common.discard") }}</template>
    <template #saveBtn>{{ t("common.save") }}</template>
  </UnsavedChangesDialog>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useConfirm } from "primevue/useconfirm";
import { UnsavedChangesDialog } from "@cumulus/components";

const { t } = useI18n();

const useTeleport = !(import.meta.env.VITE_APP_STANDALONE === "false" || import.meta.env.MODE === "test");

const confirm = useConfirm();
defineProps<{
  isSaving: boolean;
  editMode: boolean;
  hasUpdates: boolean;
  unsavedChangesDialogVisible: boolean;
}>();

const emit = defineEmits<{
  (e: "onCancel"): void;
  (e: "onDelete"): void;
  (e: "onSave"): void;
  (e: "dialogCancelBtnClicked"): void;
  (e: "dialogDiscardBtnClicked"): void;
}>();

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("company.delete-confirm-message"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      emit("onDelete");
    },
  });
};
</script>
